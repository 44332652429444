import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as QuickstartIcon } from 'assets/images/api/quickstart.svg';
import { ReactComponent as RestApiIcon } from 'assets/images/api/rest_api.svg';
import { ReactComponent as CodeExampleIcon } from 'assets/images/api/nc-tile-code.svg';
import { ReactComponent as AccelIcon } from 'assets/images/accelerators.svg';
import { ReactComponent as ClientIcon } from 'assets/images/api/quickstart.svg';

const cardsContent = (lang: string) => [
  {
    key: 'quickstart',
    icon: <QuickstartIcon />,
    title: t`Quickstart`,
    description: t`A guide to getting started with DataRobot APIs`,
    link: t`${process.env.PUBLIC_URL}/${lang}/docs/api/api-quickstart/index.html`,
  },
  {
    key: 'client-docs',
    icon: <ClientIcon />,
    title: t`Python API docs`,
    description: t`Access documentation for DataRobot's Python API client.`,
    link: t`https://datarobot-public-api-client.readthedocs-hosted.com`,
  },
  {
    key: 'user-guide',
    icon: <CodeExampleIcon />,
    title: t`API user guide`,
    description: t`Review examples and tutorials for DataRobot's API`,
    link: t`${process.env.PUBLIC_URL}/${lang}/docs/api/guide/index.html`,
  },
  {
    key: 'reference-docs',
    icon: <RestApiIcon />,
    title: t`Reference docs`,
    description: t`Documentation for DataRobot's APIs and packages`,
    link: t`${process.env.PUBLIC_URL}/${lang}/docs/api/reference/index.html`,
  },
  {
    key: 'accelerators',
    icon: <AccelIcon />,
    title: t`AI accelerators`,
    description: t`Jump-start modeling with code-first workflows`,
    link: t`${process.env.PUBLIC_URL}/${lang}/docs/api/accelerators/index.html`,
  },
];

const Api: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`DataRobot API resources`}
      subtitle={t`Use code to drive DataRobot end-to-end, from import to deployment and beyond.`}
      cardsContent={cardsContent(lang)}
    />
  );
};

export default Api;
