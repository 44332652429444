import axios from 'axios';
import Search from 'interfaces/search';

export const ALGOLIA_SEARCH = `${process.env.REACT_APP_PORTAL_API_ENDPOINT}/search`;

export const getSearchResults = (
  query: string,
  filters: string | null,
  language: string,
  page: number = 0
) =>
  axios
    .get(ALGOLIA_SEARCH, {
      params: { query, page, filters, language },
    })
    .then(({ data: search }: { data: Search }) => search);
